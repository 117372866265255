import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b8d997c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotFoundPage = _resolveComponent("NotFoundPage")!

  return (!_ctx.loadingHtml)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.existsStaticHtml)
          ? (_openBlock(), _createBlock(_component_NotFoundPage, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              innerHTML: _ctx.includeHtml
            }, null, 8, _hoisted_2))
      ]))
    : _createCommentVNode("", true)
}