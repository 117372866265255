
import { defineComponent, ref, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { getData } from '@/helpers/util/webApiUtil'
import NotFoundPage from '@/components/static/NotFound.vue'
import { useStore } from 'vuex'
import { scrollToElementTop } from '@/helpers/util/scrollUtil'

export default defineComponent({
  name: 'TheIncludeHtml',
  components: {
    NotFoundPage,
  },
  props: {
    htmlName: {
      type: String,
      default: '',
    },
    hash: {
      type: String,
    },
  },
  setup (props) {
    const CONTENTS_BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL

    const i18n = useI18n()
    const lang = i18n.locale
    const store = useStore()

    const includeHtml = ref('')
    const loadingHtml = ref(true)

    const existsStaticHtml = ref(false)

    const loadHtml = async (htmlFile: string) => {
      loadingHtml.value = true
      const langFile = htmlFile.startsWith('error/') ? htmlFile : `${lang.value}/${htmlFile}`
      await getData(`${CONTENTS_BASE_URL}static/${langFile}`).then((res) => {
        includeHtml.value = res
        existsStaticHtml.value = true
      }).catch(() => {
        // fetchでエラーとなったらNotFoundPageを表示する
        includeHtml.value = ''
        existsStaticHtml.value = false
        store.commit('SET_IS404', true)
      })
      loadingHtml.value = false
    }

    watchEffect(() => {
      if (!props.htmlName || props.htmlName === '') return
      loadHtml(props.htmlName)
    })

    watch([includeHtml, loadingHtml], () => {
      const cookieAllowedResetButton = document && document.getElementById('cookie-setting-reset-button')
      // eslint-disable-next-line no-unused-expressions
      cookieAllowedResetButton?.addEventListener('click', () => {
        store.dispatch('setCookieAllowLevel', 'none')
      })
      // eslint-disable-next-line no-unused-expressions
      cookieAllowedResetButton?.addEventListener('keydown', (e) => {
        if (['Enter', ' '].includes(e.key)) {
          store.dispatch('setCookieAllowLevel', 'none')
        }
      })
      if (props.hash) {
        scrollToElementTop(props.hash)
      }
    }, {
      flush: 'post',
    })

    return {
      includeHtml,
      existsStaticHtml,
      loadingHtml,
    }
  },
})
